<template>
    <v-progress-circular indeterminate v-if="loading" color="primary" class="align-self-center ml-4 dashboard-btn"></v-progress-circular>
    <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                    color="primary"
                    depressed
                    class="align-self-center ml-4 dashboard-btn"
                    :id="idName"
                    @click.stop="click"
                    size="36"
                    :loading="loading"
                    v-bind="attrs"
                    v-on="on"
            >
                file_upload
            </v-icon>
        </template>
        <span>Exporter des mesures</span>
    </v-tooltip>
</template>

<script>
import download from "downloadjs";
import { buildQuery } from "../elastic/queries";
import { FIELDS_SPECIAL } from "../const/fields";

import xlsx from "xlsx";
import { getSitePromise } from "../vuex/modules/site";

export default {
    props: {
        site: { type: Object },
        clientSites: { type: Array },
        childIdsToName: Object,

        // mime type [xls, csv]
        type: {
            type: String,
            default: "csv",
        },
        // this prop is used to fix the problem with other components that use the
        // variable fields, like vee-validate. exportFields works exactly like fields
        exportFields: {
            type: Object,
            default: () => null,
        },
        // Use as fallback when the row has no field values
        defaultValue: {
            type: String,
            required: false,
            default: "",
        },
        average: {
            type: Boolean,
            required: false,
            default: true,
        },
        // Title(s) for the data, could be a string or an array of strings (multiple titles)
        header: {
            default: null,
        },
        // Footer(s) for the data, could be a string or an array of strings (multiple footers)
        footer: {
            default: null,
        },
        fetch: {
            type: Function,
        },
        meta: {
            type: Array,
            default: () => [],
        },
        worksheet: {
            type: String,
            default: "Sheet1",
        },
        // Determine if CSV Data should be escaped
        escapeCsv: {
            type: Boolean,
            default: true,
        },
        // long number stringify
        stringifyLongNum: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        // unique identifier
        idName() {
            var now = new Date().getTime();
            return "export_" + now;
        },

        downloadFields() {
            if (this.fields) return this.fields;
            if (this.exportFields) return this.exportFields;
            return {};
        },
    },
    data() {
        let fields = [];

        let data = [];
        let fields_string = FIELDS_SPECIAL;
        let children_site_ids = Object.keys(this.childIdsToName);
        let map_site_id_to_name = {};

        return {
            name: "data.xlsx",
            loading: false,
            data,
            fields,
            children_site_ids,
            elastic_winery_mesures_index: "winery_mesures_" + this.site.client_id,
            elastic_winery_events_index: "winery_events_" + this.site.client_id,
            fields_string,
            map_site_id_to_name,
        };
    },
    async mounted() {
        this.fields = await this.getFields();
    },
    methods: {
        showErr(err) {
            console.log(err);
            this.$store.commit("setSnack", {
                type: "red",
                err,
            });
        },
        async getFields() {
            let fields = {};

            for (let mesure of this.site.sensors) {
                fields[mesure.name] = mesure.csv_column;
            }
            if (this.childIdsToName) {
                for (let child of Object.keys(this.childIdsToName)) {
                    let childSite = await this.getSite(child);
                    if (childSite) {
                        console.log("childSite sensors", childSite.sensors);
                        for (let mesure of childSite.sensors) {
                            fields[mesure.name] = mesure.csv_column;
                        }
                    }
                }
            }
            fields["DateTime"] = "@timestamp";
            fields["Site"] = "Site";
            fields["events"] = "events";
            return fields;
        },
        async initMapSiteName() {
            let siteIdToName = {};
            siteIdToName[this.site.id] = this.site.name;
            this.map_site_id_to_name = { ...siteIdToName, ...this.childIdsToName };
        },
        initName() {
            let currentDate = new Date();
            let year = currentDate.getFullYear();
            let month = currentDate.getMonth() + 1;
            let day = currentDate.getDate();
            let hours = currentDate.getHours();
            let minutes = currentDate.getMinutes();
            this.name =
                "data_" + this.site.name + "_" + year + month + day + hours + minutes + ".xlsx";
        },
        async getSite(child_id) {
            let childSite;
            let filteredSites = this.clientSites.filter((s) => s.id == child_id);
            if (filteredSites.length == 1) {
                childSite = filteredSites[0];
            } else if (filteredSites == 0 && this.$store.state.profile.user.role == "admin") {
                childSite = await getSitePromise(this.$store, child_id);
            } else {
                console.log(
                    "Error looking" + child_id + " in client Sites  : ",
                    this.clientSites
                );
            }
            return childSite;
        },
        getSiteName(site_id) {
            return this.map_site_id_to_name[site_id];
        },
        getFullSiteElasticData() {
            this.data = [];
            return new Promise((resolve, reject) => {
                let elasticQuery = buildQuery(this.site.id, this.children_site_ids, "desc");

                console.log("elastic query", elasticQuery);

                this.$store
                    .dispatch("elasticDownloadRequest", {
                        index: this.elastic_winery_mesures_index,
                        body: elasticQuery,
                        site: this.site.id,
                    })
                    .then((result) => {
                        resolve(result.data);
                    })
                    .catch((err) => {
                        this.$store.commit("setSnack", {
                            type: "red",
                            err,
                        });
                        reject(err);
                    });
            });
        },

        async click() {
            this.loading = true;
            this.initName();
            this.initMapSiteName();
            let hits = await this.getFullSiteElasticData().catch((err) => {
                this.showErr(err);
            });
            console.log("let's export " + hits.length + "hits");
            for (let hit of hits) {
                if (hit._source && hit._source.Site) {
                    let site_id = hit._source.Site;
                    hit._source.Site = this.getSiteName(site_id);
                    this.data.push(hit._source);
                } else {
                    // console.log("wrong hit !? : " + hit);
                }
            }
            this.generate();
        },

        async generate() {
            let data = this.data;
            if (typeof this.fetch === "function" || !data) data = await this.fetch();

            if (!data || !data.length) {
                this.loading = false;
                return;
            }
            console.log(this.downloadFields);
            let json = this.getProcessedJson(data, this.downloadFields);
            let status;
            console.log(this.type);
            if (this.type === "html") {
                // this is mainly for testing
                status = this.export(
                    this.jsonToXLS(json),
                    this.name.replace(".xls", ".html"),
                    "text/html"
                );
            } else if (this.type === "csv") {
                status = this.export(
                    this.jsonToCSV(json),
                    this.name.replace(".xlsx", ".csv"),
                    "application/csv"
                );
            } else {
                status = this.jsonToXLSX(json);
            }
            this.loading = false;
            return status;
        },
        /*
        Use downloadjs to generate the download link
        */
        export: async function (data, filename, mime) {
            let blob = this.base64ToBlob(data, mime);
            Object.assign(document.createElement('a'), { href: URL.createObjectURL(blob), download: filename }).click();
            //download(blob, filename, mime);
        },
        /*
        jsonToXLS
        ---------------
        Transform json data into an xml document with MS Excel format, sadly
        it shows a prompt when it opens, that is a default behavior for
        Microsoft office and cannot be avoided. It's recommended to use CSV format instead.
        */
        jsonToXLS(data) {
            let xlsTemp =
                '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${table}</table></body></html>';
            let xlsData = "<thead>";
            const colspan = Object.keys(data[0]).length;
            let _self = this;

            //Header
            const header = this.header || this.$attrs.title;
            if (header) {
                xlsData += this.parseExtraData(
                    header,
                    '<tr><th colspan="' + colspan + '">${data}</th></tr>'
                );
            }

            //Fields
            xlsData += "<tr>";
            for (let key in data[0]) {
                xlsData += "<th>" + key + "</th>";
            }
            xlsData += "</tr>";
            xlsData += "</thead>";

            //Data
            xlsData += "<tbody>";
            data.map(function (item, index) {
                xlsData += "<tr>";
                for (let key in item) {
                    xlsData +=
                        "<td>" +
                        _self.preprocessLongNum(_self.valueReformattedForMultilines(item[key])) +
                        "</td>";
                }
                xlsData += "</tr>";
            });
            xlsData += "</tbody>";

            //Footer
            if (this.footer != null) {
                xlsData += "<tfoot>";
                xlsData += this.parseExtraData(
                    this.footer,
                    '<tr><td colspan="' + colspan + '">${data}</td></tr>'
                );
                xlsData += "</tfoot>";
            }

            return xlsTemp.replace("${table}", xlsData).replace("${worksheet}", this.worksheet);
        },
        /*
        jsonToCSV
        ---------------
        Transform json data into an CSV file.
        */
        jsonToCSV(data) {
            let _self = this;
            var csvData = [];

            //Header
            const header = this.header || this.$attrs.title;
            if (header) {
                csvData.push(this.parseExtraData(header, "${data}\r\n"));
            }

            //Fields
            for (let key in data[0]) {
                csvData.push(key);
                csvData.push(",");
            }
            csvData.pop();
            csvData.push("\r\n");
            //Data
            data.map(function (item) {
                for (let key in item) {
                    let escapedCSV = item[key] + "";
                    // Escaped CSV data to string to avoid problems with numbers or other types of values
                    // this is controlled by the prop escapeCsv
                    if (_self.escapeCsv) {
                        //escapedCSV = '="' + escapedCSV + '"'; // cast Numbers to string
                        /*if (escapedCSV.match(/[,"\n]/)) {
                            escapedCSV = '"' + escapedCSV.replace(/"/g, '""') + '"';
                        }*/
                    }
                    csvData.push(escapedCSV);
                    csvData.push(",");
                }
                csvData.pop();
                csvData.push("\r\n");
            });
            //Footer
            if (this.footer != null) {
                csvData.push(this.parseExtraData(this.footer, "${data}\r\n"));
            }
            return csvData.join("");
        },

        jsonToXLSX(data) {
            var exportBook = xlsx.utils.book_new();
            let worksheet = xlsx.utils.json_to_sheet(data);

            if (this.average && data.length > 0) {
                let nbRows = 1 + data.length + 1;
                let keys = Object.keys(data[0]);
                let nbColumns = keys.length;
                worksheet["!ref"] = xlsx.utils.encode_range({
                    s: { c: 0, r: 0 },
                    e: { c: nbColumns, r: nbRows },
                });

                for (let col = 0; col < nbColumns; col++) {
                    if (this.fields_string.includes(keys[col]) || keys[col] == "events") {
                        continue;
                    }
                    let colName = xlsx.utils.encode_col(col);
                    let cellRef = xlsx.utils.encode_cell({ r: nbRows, c: col });
                    let averageRowMin = 2;
                    let averageRowMax = nbRows - 1;
                    let cell = {
                        f: "AVERAGE(" + colName + averageRowMin + ":" + colName + averageRowMax + ")",
                    };
                    worksheet[cellRef] = cell;
                }
                let cellRef = xlsx.utils.encode_cell({
                    r: nbRows,
                    c: nbColumns,
                });
                worksheet[cellRef] = { v: "Moyenne" };
            }
            exportBook.SheetNames.push(this.worksheet);
            exportBook.Sheets[this.worksheet] = worksheet;
            xlsx.writeFile(exportBook, this.name);
            data = [];
        },

        /*
        getProcessedJson
        ---------------
        Get only the data to export, if no fields are set return all the data
        */
        getProcessedJson(data, header) {
            let keys = this.getKeys(data, header);
            let newData = [];
            let _self = this;
            data.map(function (item, index) {
                let newItem = {};
                for (let label in keys) {
                    let property = keys[label];
                    newItem[label] = _self.getValue(property, item);
                }
                newData.push(newItem);
            });
            for (let row of newData) {
                if (row.events) {
                    row.events = row.events.toString();
                }
            }

            return newData;
        },
        getKeys(data, header) {
            if (header) {
                return header;
            }

            let keys = {};
            for (let key in data[0]) {
                keys[key] = key;
            }
            return keys;
        },
        /*
        parseExtraData
        ---------------
        Parse title and footer attribute to the csv format
        */
        parseExtraData(extraData, format) {
            let parseData = "";
            if (Array.isArray(extraData)) {
                for (var i = 0; i < extraData.length; i++) {
                    if (extraData[i]) parseData += format.replace("${data}", extraData[i]);
                }
            } else {
                parseData += format.replace("${data}", extraData);
            }
            return parseData;
        },

        getValue(key, item) {
            const field = typeof key !== "object" ? key : key.field;
            let indexes = typeof field !== "string" ? [] : field.split(".");
            let value = this.defaultValue;

            if (!field) value = item;
            else if (indexes.length > 1) value = this.getValueFromNestedItem(item, indexes);
            else value = this.parseValue(item[field]);

            if (key.hasOwnProperty("callback"))
                value = this.getValueFromCallback(value, key.callback);

            return value;
        },

        /*
        convert values with newline \n characters into <br/>
        */
        valueReformattedForMultilines(value) {
            if (typeof value == "string") return value.replace(/\n/gi, "<br/>");
            else return value;
        },
        preprocessLongNum(value) {
            if (this.stringifyLongNum) {
                if (String(value).startsWith("0x")) {
                    return value;
                }
                if (!isNaN(value) && value != "") {
                    if (value > 99999999999 || value < 0.0000000000001) {
                        return '="' + value + '"';
                    }
                }
            }
            return value;
        },
        getValueFromNestedItem(item, indexes) {
            let nestedItem = item;
            for (let index of indexes) {
                if (nestedItem) nestedItem = nestedItem[index];
            }
            return this.parseValue(nestedItem);
        },

        getValueFromCallback(item, callback) {
            if (typeof callback !== "function") return this.defaultValue;
            const value = callback(item);
            return this.parseValue(value);
        },
        parseValue(value) {
            return value || value === 0 || typeof value === "boolean"
                ? value
                : this.defaultValue;
        },
        base64ToBlob(data, mime) {
            let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
            let bstr = atob(base64);
            let n = bstr.length;
            let u8arr = new Uint8ClampedArray(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
    }, // end methods
};
</script>